import Button from 'components/Button/Index'
import { useEffect, useState } from 'react'
import guestmarketingService from 'services/guestmarketing.service'

const Redeem = () => {
  const searchParameters = new URLSearchParams(
    window.location.search.split('?')[1]
  )
  const token = searchParameters.get('token')
  const campaignId = Number(searchParameters.get('campaign_id'))
  const decodedEmail = decodeURIComponent(token)

  const [coupenCode, setCouponCode] = useState<string>('')
  const [showCouponBox, setShowCouponBox] = useState<boolean>(false)
  const [restaurantLogoPath, setRestaurantLogoPath] = useState<string>('')
  const [isRedeem, setIsRedeem] = useState<boolean>(false)
  const [offerIsInActive, setOfferIsInActive] = useState<boolean>()
  const [redeemLimit, setRedeemLimit] = useState<number>()

  const getCampaignPayload = () => {
    return { campaignId, emailId: decodedEmail }
  }

  const getCampaignDetails = async () => {
    const response = await guestmarketingService.getCampaignInvite(
      getCampaignPayload()
    )
    const campaignDetail = response.data.data
    setRestaurantLogoPath(campaignDetail.businessLogoPath)
    setCouponCode(campaignDetail.couponCode)
    setRedeemLimit(campaignDetail.redeemLimit)
    setIsRedeem(campaignDetail.isRedeem)
    const startDate = new Date(campaignDetail.offerStartDate)
    const currDate = new Date()

    startDate.setHours(0, 0, 0, 0)
    currDate.setHours(0, 0, 0, 0)
    if (startDate.getTime() > currDate.getTime()) {
      setOfferIsInActive(true)
    }
  }

  const onGenerateClick = async () => {
    setShowCouponBox(true)
    await guestmarketingService.updateCampaignInvite({
      campaignId,
      emailId: decodedEmail,
      isRedeem,
      redeemLimit,
    })
  }

  useEffect(() => {
    getCampaignDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className="wrapper"
      style={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      {!offerIsInActive && !isRedeem && (
        <div className="redeem-page-container">
          <div className="redeem-header">
            <h2>Congratulations!</h2>
            <p>
              “Woo Hoo! Please ensure you show this reward and your original
              email to your server before exiting out of the page!
            </p>
          </div>

          {showCouponBox && (
            <div className="coupon-code-container">
              <p>
                Your Redeem code is:{' '}
                <span style={{ fontWeight: 'bold' }}>{coupenCode}</span>
              </p>
            </div>
          )}
          {!showCouponBox && (
            <Button
              type="button"
              onClick={onGenerateClick}
              variant="contained"
              color="primary"
            >
              Generate Coupon
            </Button>
          )}

          {restaurantLogoPath && (
            <div
              style={{
                height: '100px',
                width: '100px',
                display: 'flex',
                marginTop: '20px',
              }}
            >
              <img
                src={restaurantLogoPath}
                alt="Restaurant logo"
                style={{ width: '100px', margin: 'auto' }}
              />
            </div>
          )}
        </div>
      )}
      {isRedeem && (
        <div className="redeem-page-container">
          <div className="redeem-header">
            <h2>Whoops! Coupon Has Been Previously Used</h2>
            <p style={{ marginTop: '20px', textAlign: 'center' }}>
              It looks like this coupon code has already been redeemed
            </p>
          </div>
          {restaurantLogoPath && (
            <div
              style={{
                height: '100px',
                width: '100px',
                display: 'flex',
                marginTop: '20px',
              }}
            >
              <img
                src={restaurantLogoPath}
                alt="Restaurant logo"
                style={{ width: '100px', margin: 'auto' }}
              />
            </div>
          )}
        </div>
      )}
      {offerIsInActive && (
        <div className="redeem-page-container">
          <div className="redeem-header">
            <h2>This Offer is Currently Not Active</h2>
            <p style={{ marginTop: '20px', textAlign: 'center' }}>
              Please Check Offer Start Date from received Email.
            </p>
          </div>
          {restaurantLogoPath && (
            <div
              style={{
                height: '100px',
                width: '100px',
                display: 'flex',
                marginTop: '20px',
              }}
            >
              <img
                src={restaurantLogoPath}
                alt="Restaurant logo"
                style={{ width: '100px', margin: 'auto' }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Redeem
